const G = {
	isMock: false,
	ENV: import.meta.env.MODE,
	platform: import.meta.env.VITE_PLATFORM,
	defaultTitle: '运营平台',
	isKeepAlive: false,
}
export default G
export const DOMAIN_MAP = {
	question: import.meta.env.VITE_QUESTION_URL,
	mistake: import.meta.env.VITE_MISTAKE_URL,
	artificial: import.meta.env.VITE_ARTIFICIAL_URL,
	operation: import.meta.env.VITE_OPERATION_URL,
	task: import.meta.env.VITE_DATATASK_URL,
	homework: import.meta.env.VITE_HOMEWORK_URL,
	goods: import.meta.env.VITE_GOODS_URL,
	question2: import.meta.env.VITE_QUESTION_URL2,
}
