/**
 * 枚举相关api
 */
import { cloneDeep } from 'lodash'
import G from '@/config/config'
import { EnvName } from '@/config/NodeEnv'
import store from '@/store'
import { VuexModuleKey, VuexStateKey } from '@/store/vuexEnum'
import http from '@kzfe/learnable-lib/package/vue3/framework/utils/http'

// 学校相关枚举
export interface AllTypeEnumList {
	subjectEnum: EnumItem[]
	subjectTypeEnum: EnumItem[]
	teachingVersionEnum: EnumItem[]
	reviewQuestionTypeEnum: EnumItem[]
}
export interface EnumItem {
	index: number
	desc: string
	parentIndexList: number[] | null
}
type ExtendConfig = { color?: string; defaultCheck?: boolean }

export type EnumKey = keyof AllTypeEnumList
// 获取全部枚举
export function apiQueryAllEnum() {
	return http<AllTypeEnumList>(
		'GET',
		'/enums/queryEnumList',
		{},
		{
			domainName: 'question',
		}
	)
}

const DEFAULT_SERVER_ENUM: EnumItem = {
	index: -1,
	desc: '',
}

export function getServerEnumByName(enumName: EnumKey) {
	const enumModule = store.state[VuexModuleKey.enumModule] as unknown as {
		[key: string]: unknown
	}
	const enums = enumModule[VuexStateKey.serverAllEnum] as unknown as AllTypeEnumList
	return enums[enumName]
}

// 根据code获取对应枚举
export function getServerEnumObjByCode(listName: EnumKey, code: number): EnumItem {
	const resultDefaultEnum = cloneDeep(DEFAULT_SERVER_ENUM) as EnumItem
	const envIsPro = G.ENV === EnvName.PRODUCTION
	const enumModule = store.state[VuexModuleKey.enumModule] as unknown as {
		[key: string]: unknown
	}
	const enums = enumModule[VuexStateKey.serverAllEnum] as unknown as AllTypeEnumList
	const list = enums[listName]
	if (!list) {
		resultDefaultEnum.desc = `getServerEnumObjByCode: can't find list array.`
		return envIsPro ? DEFAULT_SERVER_ENUM : resultDefaultEnum
	}
	const result = list.filter(item => item.index == code)
	if (result.length === 0) {
		resultDefaultEnum.index = code
		resultDefaultEnum.desc = `code 【${code}】 can't find in ${JSON.stringify(list)}`
		return envIsPro ? DEFAULT_SERVER_ENUM : resultDefaultEnum
	} else {
		return result[0]
	}
}
