<template>
	<el-form
		class="form"
		ref="formRef"
		v-if="model"
		:model="model"
		:rules="rules"
		:validate-on-rule-change="false"
		label-position="right"
		label-suffix=":"
		v-bind="$attrs"
	>
		<template v-for="(item, index) in options" :key="index">
			<template v-if="hideFormItems.indexOf(item.prop!) === -1">
				<template v-if="!item.isCustom && item.type !== 'autocomplete' && item.type !== 'date-picker'">
					<!--不存在子表单项-->
					<el-form-item v-if="item.type !== 'upload' && (!item.children || !item.children.length)" :prop="item.prop" :label="item.label">
						<el-input
							v-if="item.type === 'input'"
							:placeholder="item.placeholder"
							v-model="model[item.prop!]"
							v-bind="item.attrs"
							@blur="handleInputBlur($event, item.prop!)"
							@input="handleInput($event, item.prop!)"
							@change="handleInputChange"
						/>
						<component v-else :is="`el-${item.type}`" :placeholder="item.placeholder" v-model="model[item.prop!]" v-bind="item.attrs">
							<template #default="scope">
								<slot :name="`${item.prop}Default`" :scope="scope"></slot>
							</template>
						</component>
						<!--上传组件-->
					</el-form-item>
					<el-form-item v-if="item.type === 'upload'" :label="item.label">
						<el-upload
							ref="uploadRef"
							v-if="item.type === 'upload'"
							v-bind="item.uploadAttrs as any"
							:on-preview="onPreview"
							:on-remove="onRemove"
							:on-success="onSuccess"
							:on-error="onError"
							:on-progress="onProgress"
							:on-change="onChange"
							:before-upload="beforeUpload"
							:before-remove="beforeRemove"
							:on-exceed="onExceed"
							:http-request="httpRequest"
						>
							<slot name="uploadArea"></slot>
							<slot name="uploadTip"></slot>
						</el-upload>
					</el-form-item>
					<!--存在子表单项-->
					<el-form-item v-if="item.children && item.children.length" :prop="item.prop" :label="item.label">
						<el-select
							v-if="item.type === 'select'"
							:placeholder="item.placeholder"
							v-model="model[item.prop!]"
							v-bind="item.attrs"
							@change="handleSelectChange(item.prop!)"
						>
							<el-option v-for="child in item.children" :key="child.key" :label="child.label" :value="child.value"></el-option>
						</el-select>
						<component v-else :is="`el-${item.type}`" :placeholder="item.placeholder" v-model="model[item.prop!]" v-bind="item.attrs">
							<template v-for="(child, i) in item.children">
								<component v-if="child.type !== 'radio'" :key="i" :is="`el-${child.type}`" :label="child.label" :value="child.value"> </component>
								<el-radio v-else :label="child.label" :key="child.label">{{ child.value }}</el-radio>
							</template>
						</component>
						<slot v-if="item.slot" :name="`${item.slot}`"></slot>
					</el-form-item>
				</template>
				<!--远程搜索框-->
				<template v-else-if="item.type === 'autocomplete'">
					<el-form-item :prop="item.prop" :label="item.label">
						<el-autocomplete
							v-model="fetchValue"
							:placeholder="item.placeholder"
							:fetch-suggestions="fetchSuggestions"
							:trigger-on-focus="false"
							clearable
							@select="fetchSelect"
							@change="fetchChange"
							v-bind="item.attrs"
						/>
					</el-form-item>
				</template>
				<!--时间选择框-->
				<template v-else-if="item.type === 'date-picker'">
					<el-form-item :prop="item.prop" :label="item.label">
						<el-date-picker
							v-if="item.dateBetween"
							v-model="model[item.prop!]"
							:placeholder="item.placeholder"
							v-bind="item.attrs"
							@calendar-change="calendarChange"
							:disabled-date="disabledDate"
						></el-date-picker>
						<el-date-picker v-else v-model="model[item.prop!]" :placeholder="item.placeholder" v-bind="item.attrs"></el-date-picker>
					</el-form-item>
				</template>
				<template v-else>
					<!--扩展位，用于扩展定制化表单-->
					<el-form-item :prop="item.prop" :label="item.label">
						<slot :name="item.customName" :form="formRef" :model="model"></slot>
					</el-form-item>
				</template>
			</template>
		</template>
		<el-form-item>
			<slot name="action" :form="formRef" :model="model"></slot>
		</el-form-item>
	</el-form>
</template>

<script lang="ts" setup>
import { ElUpload } from 'element-plus'
import { FormOptions, FormInstance, FetchSearchItem } from './types/types'
import { PropType, onMounted, watch, defineEmits, defineProps, defineExpose } from 'vue'
import { cloneDeep } from 'lodash'
import { UploadFile } from 'element-plus/es/components/upload/src/upload'
const emits = defineEmits([
	'on-preview',
	'on-remove',
	'on-success',
	'on-error',
	'on-progress',
	'on-change',
	'before-upload',
	'before-remove',
	'on-exceed',
	'fetch-suggestions',
	'fetch-select',
	'fetch-change',
	'input-blur',
	'input-change',
	'input',
	'select-change',
	'select-clear',
	'calendar-change',
])
const props = defineProps({
	options: {
		type: Array as PropType<FormOptions[]>,
		required: true,
	},
	// 用户自定义上传方法
	httpRequest: {
		type: Function,
	},
	// 记录需要隐藏的表单
	hideFormItems: {
		type: Array as PropType<string[]>,
		default: () => [],
	},
})
type ModelType = { [key: string]: unknown }
type RuleType = { [key: string]: unknown }
// 表单模型
let model = $ref<ModelType>()
let rules = $ref<RuleType>()
// 记录远程搜索框key
let fetchKey = ''
// 记录远程搜索框值
let fetchValue = $ref('')
// 记录时间选框key
let datePickerKey = ''
// 记录配置的动态禁用时间范围
let dateBetween = $ref(0)
// 记录选择的时间
let pickDate = $ref<Date>()
// 表单实例
const formRef = $ref<FormInstance | null>()
// 文件上传器
const uploadRef = $ref<InstanceType<typeof ElUpload>>()

// 初始化表单
const initForm = () => {
	if (props.options && props.options.length) {
		const m: ModelType = {}
		const r: RuleType = {}
		props.options.map((item: FormOptions) => {
			if (item.prop && props.hideFormItems.indexOf(item.prop) === -1) {
				// 处理远程搜索框
				if (item.type === 'autocomplete') {
					fetchKey = item.prop!
				}
				// 处理时间范围选框-需要动态计算时间选择范围
				if (item.type === 'date-picker' && item.dateBetween) {
					datePickerKey = item.prop!
					dateBetween = item.dateBetween
				}
				// 用于处理value是function(如时间控件)
				m[item.prop!] = typeof item.value === 'function' ? item.value() : item.value
				r[item.prop!] = item.rules
			}
		})
		// 使用深拷贝，防止会影响
		model = cloneDeep(m)
		rules = cloneDeep(r)
	}
}

const initRules = async () => {
	if (props.options && props.options.length) {
		const r: RuleType = {}
		props.options.map((item: FormOptions) => {
			if (item.prop && props.hideFormItems.indexOf(item.prop) === -1) {
				r[item.prop!] = item.rules
			}
		})
		rules = cloneDeep(r)
	}
}

onMounted(() => {
	initForm()
})

// watch(
// 	() => props.options,
// 	() => {
// 		initForm()
// 	},
// 	{ deep: true }
// )
watch(
	() => props.options,
	() => {
		initRules()
	},
	{ deep: true }
)

// 上传文件所有的钩子函数
// 点击文件列表中已上传的文件时
const onPreview = () => {
	emits('on-preview')
}
// 文件列表移除文件时
const onRemove = (file: UploadFile, fileList: UploadFile[]) => {
	emits('on-remove', { file, fileList })
}
// 文件上传成功时
const onSuccess = (response: unknown, file: UploadFile, fileList: UploadFile[]) => {
	// 上传图片成功 给表单上传项赋值
	const uploadItem = props.options.find(item => item.type === 'upload')!
	model[uploadItem.prop!] = { response, file, fileList }
	// 重置校验
	formRef!.validateField(uploadItem.prop!, () => true)
	emits('on-success', { response, file, fileList })
}
// 文件上传失败时
const onError = (err: unknown, file: UploadFile, fileList: UploadFile[]) => {
	emits('on-error', { err, file, fileList })
}
// 文件上传过程中
const onProgress = (event: unknown, file: UploadFile, fileList: UploadFile[]) => {
	emits('on-progress', { event, file, fileList })
}
// 文件状态改变时（添加、上传成功、上传失败）
const onChange = (file: UploadFile, fileList: UploadFile[]) => {
	emits('on-change', { file, fileList })
}
// 上传文件之前（返回false，或者返回Promise且被reject则终止上传）
const beforeUpload = (file: UploadFile) => {
	emits('before-upload', file)
}
// 删除文件之前（返回false，或者返回Promise且被reject则终止删除）
const beforeRemove = (file: UploadFile, fileList: UploadFile[]): boolean => {
	emits('before-remove', { file, fileList })
	return true
}
// 文件超出个数限制时
const onExceed = (files: UploadFile, fileList: UploadFile[]) => {
	emits('on-exceed', { files, fileList })
}

// 远程搜索
const fetchSuggestions = (queryString: string, cb?: (arg: FetchSearchItem[]) => void) => {
	emits('fetch-suggestions', { queryString, cb })
}

// 远程搜索-选中某一项
const fetchSelect = (item: FetchSearchItem) => {
	model[fetchKey] = item.link
	// 清空表单校验
	formRef?.clearValidate(fetchKey)
	emits('fetch-select', item, { model, formRef })
}

// 远程搜索-内容改变时触发
const fetchChange = (val: string) => {
	if (!val) {
		fetchValue = ''
		model[fetchKey] = ''
	}
	emits('fetch-change', val, { model, formRef })
}

// 远程搜索-赋值搜索框
const setFetchValue = (val: string) => {
	fetchValue = val
	fetchSuggestions(val)
}
// 选择时间时触发
const calendarChange = (time: Date[]) => {
	const [pointDate] = time
	pickDate = pointDate
	emits('calendar-change', time)
}
// 自定义动态禁用时间
const disabledDate = (time: Date) => {
	if (!pickDate) {
		return time.getTime() > Date.now()
	} else {
		if (dateBetween) {
			const con1 = pickDate.getTime() - dateBetween * 24 * 3600 * 1000
			const con2 = pickDate.getTime() + dateBetween * 24 * 3600 * 1000
			return time.getTime() < con1 || time.getTime() > con2
		} else {
			return false
		}
	}
}
// 重置表单(自定义)
const resetFields = () => {
	// 重置element-plus的表单
	formRef!.resetFields()
	// 重置远程搜索框
	if (fetchValue !== '') {
		fetchValue = ''
	}
	if (props.options && props.options.length) {
		// 重置文件上传器
		const uploadItem = props.options.find(item => item.type === 'upload')
		if (uploadItem) {
			uploadRef.clearFiles()
		}
	}
}
// 表单验证方法
const validate = () => {
	return formRef!.validate
}

// 清除表单项校验
const clearValidate = () => {
	return formRef!.clearValidate
}

// 获取表单数据方法
const getFormData = () => {
	return model
}

// 获取表单实例
const getForm = () => {
	return formRef
}
// 设置表单数据（用户回显）
const setFormData = (newModel: any) => {
	model = cloneDeep(newModel)
}

/** input相关方法处理 */
const handleInputBlur = (event: Event, prop: string) => {
	emits('input-blur', prop)
}

const handleInput = (event: Event, prop: string) => {
	emits('input', prop)
}

const handleInputChange = (val: string | number, prop: string) => {
	emits('input-change', prop, val)
}

/**select相关处理方法 */
const handleSelectChange = (prop: string) => {
	emits('select-change', prop)
}

// 分发方法
defineExpose({
	resetFields,
	validate,
	getForm,
	getFormData,
	clearValidate,
	setFormData,
	fetchChange,
	setFetchValue,
	calendarChange,
})
</script>
<style lang="scss" scoped>
.form {
	padding: 20px;
}
</style>
