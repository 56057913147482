import { LocalStorageKeyG } from '@kzfe/learnable-lib/package/vue3/framework/config/enum'
export class UrlQueryKey {}

export class LocalStorageKey extends LocalStorageKeyG {}
export class TimeKey {}
export class SessionStorageKey {}

export class EventKey {}

export class ErrorServerKey {}
