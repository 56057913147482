import { setPageTitle } from '@/utils/common/system'
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { routerBeforeEach, settingRoutesG, frameworkRoutes, recursionGetConstRoute } from '@kzfe/learnable-lib/package/vue3/framework/router/index'
// @kzfe/learnable-lib/package/vue3/framework
import Main from '@kzfe/learnable-lib/package/vue3/framework/components/Main/Main.vue'
import NProgress from 'nprogress' // progress bar
import { initFramework } from '@kzfe/learnable-lib/package/vue3/framework/config/config'
import G, { DOMAIN_MAP } from '@/config/config'
import store from '@/store'
import { isLogin } from '@kzfe/learnable-lib/package/vue3/framework/utils/common/user'
import { VuexActionKey, VuexModuleKey, VuexStateKey } from '@/store/vuexEnum'
import { EnumItem } from '@/api/enum'

const routes: Array<RouteRecordRaw> = [
	{
		path: '/agency',
		name: 'userModule',
		component: Main,
		meta: {
			title: '机构管理',
			icon: '&#xe63f;',
			permission: 'agency',
			menuShow: true,
		},
		children: [
			{
				path: '/agency/class',
				name: 'userClass',
				component: () => import('@/views/agency/class/Class.vue'),
				meta: {
					icon: '&#xe611;',
					title: '班级管理',
					menuShow: false,
					permission: 'agency:class',
				},
			},
			{
				path: '/agency/list',
				name: 'agency',
				component: () => import('@/views/agency/agency/Agency.vue'),
				meta: {
					icon: '&#xe62f;',
					title: '机构管理',
					menuShow: true,
					permission: 'agency:agency',
				},
			},
			{
				path: '/agency/school',
				name: 'userSchool',
				component: () => import('@/views/agency/school/School.vue'),
				meta: {
					icon: '&#xe659;',
					title: '学校管理',
					menuShow: true,
					permission: 'agency:school',
				},
			},
			{
				path: '/agency/student',
				name: 'userStudent',
				component: () => import('@/views/agency/student/Student.vue'),
				meta: {
					icon: '&#xe63e;',
					title: '学生管理',
					menuShow: false,
					permission: 'agency:student',
				},
			},
			{
				path: '/agency/teacher',
				name: 'userTeacher',
				component: () => import('@/views/agency/teacher/Teacher.vue'),
				meta: {
					icon: '&#xe6a2;',
					title: '教师管理',
					menuShow: false,
					permission: 'agency:teacher',
				},
			},
			{
				path: '/agency/operator',
				name: 'userOperator',
				component: () => import('@/views/agency/operator/Operator.vue'),
				meta: {
					icon: '&#xe6a2;',
					title: '运营管理',
					menuShow: false,
					permission: 'agency:operator',
				},
			},
			{
				path: '/agency/user',
				name: 'agencyUser',
				component: () => import('@/views/agency/user/User.vue'),
				meta: {
					icon: '&#xe6a2;',
					title: '用户管理',
					menuShow: true,
					permission: 'agency:user',
				},
			},
			{
				path: '/agency/serviceConfiguration',
				name: 'serviceConfiguration',
				component: () => import('@/views/agency/serviceConfiguration/ServiceConfiguration.vue'),
				meta: {
					icon: '&#xe6a2;',
					title: '学校服务配置',
					menuShow: true,
					permission: 'agency:service_configuration',
				},
			},
			{
				path: '/agency/schoolServiceConfiguration',
				name: 'schoolServiceConfiguration',
				component: () => import('@/views/agency/serviceConfiguration/SchoolServiceConfiguration.vue'),
				meta: {
					icon: '&#xe6a2;',
					title: '学校服务配置',
					menuShow: false,
					permission: 'agency:service_configuration',
				},
			},
			{
				path: '/agency/agencyServiceConfiguration',
				name: 'agencyServiceConfiguration',
				component: () => import('@/views/agency/agencyServiceConfiguration/AgencyServiceConfiguration.vue'),
				meta: {
					icon: '&#xe6a2;',
					title: '机构服务配置',
					menuShow: true,
					permission: 'agency:agency_service_configuration',
				},
			},
			{
				path: '/agency/agencyServiceConfigurationDetail',
				name: 'agencyServiceConfigurationDetail',
				component: () => import('@/views/agency/agencyServiceConfiguration/AgencyServiceConfigurationDetail.vue'),
				meta: {
					icon: '&#xe6a2;',
					title: '机构服务配置',
					menuShow: false,
					permission: 'agency:agency_service_configuration',
				},
			},
			{
				path: '/agency/channelManage',
				name: 'agencyChannelManage',
				component: () => import('@/views/agency/channel/ChannelManage.vue'),
				meta: {
					icon: '&#xe6a2;',
					title: '渠道管理',
					menuShow: true,
					permission: 'agency:channel_manage',
				},
			},
			{
				path: '/agency/channelUserManage',
				name: 'agencyChannelUserManage',
				component: () => import('@/views/agency/channel/ChannelUserManage.vue'),
				meta: {
					icon: '&#xe6a2;',
					title: 'C端用户管理',
					menuShow: false,
					permission: 'agency:user',
				},
			},
			{
				path: '/agency/channelOperatorManage',
				name: 'agencyChannelOperatorManage',
				component: () => import('@/views/agency/channel/ChannelOperatorManage.vue'),
				meta: {
					icon: '&#xe6a2;',
					title: '运营管理',
					menuShow: false,
					permission: 'agency:user',
				},
			},
			{
				path: '/agency/channelExerciseConfig',
				name: 'agencyChannelExerciseConfig',
				component: () => import('@/views/agency/channel/ChannelExerciseConfig.vue'),
				meta: {
					icon: '&#xe6a2;',
					title: '练习册配置',
					menuShow: false,
					permission: 'agency:user',
				},
			},
		],
	},
	{
		path: '/appManage',
		name: 'appManage',
		component: Main,
		meta: {
			icon: '&#xe640;',
			title: 'App运营管理',
			menuShow: true,
			permission: 'appManage',
		},
		children: [
			{
				path: '/appManage/update',
				name: 'appUpdateManage',
				component: () => import('@/views/appManage/update/Index.vue'),
				meta: {
					icon: '&#xe640;',
					title: 'App升级管理',
					menuShow: true,
					permission: 'appManage:update',
				},
			},
		],
	},
	{
		path: '/DKTOperationManage',
		name: 'DKTOperationManage',
		component: Main,
		meta: {
			icon: '&#xe640;',
			title: '大考通运营管理',
			menuShow: true,
			permission: 'DKTOperationManage',
		},
		children: [
			{
				path: '/DKTOperationManage/channelManage',
				name: 'DKTOperationManageChannelManage',
				component: () => import('../views/DKTOperationManage/channelManage/channelManage.vue'),
				meta: {
					icon: '&#xe640;',
					title: '渠道管理',
					menuShow: true,
					permission: 'DKTOperationManageChannelManage',
				},
			},
			{
				path: '/DKTOperationManage/channelManage/user',
				name: 'DKTOperationManageChannelUser',
				component: () => import('@/views/DKTOperationManage/channelManage/channelUser.vue'),
				meta: {
					icon: '&#xe77f;',
					title: '渠道用户',
					menuShow: false,
					permission: 'dkt:channelUser',
				},
			},
			{
				path: '/DKTOperationManage/channelManage/order',
				name: 'DKTOperationManageChannelOrder',
				component: () => import('@/views/DKTOperationManage/channelManage/channelOrder.vue'),
				meta: {
					icon: '&#xe77f;',
					title: '渠道订单',
					menuShow: false,
					permission: 'dkt:channelOrder',
				},
			},
			{
				path: '/DKTOperationManage/examBomManage',
				name: 'DKTOperationManageExamBomManage',
				component: () => import('../views/DKTOperationManage/examBomManage/examBomManage.vue'),
				meta: {
					icon: '&#xe640;',
					title: '试卷生产管理',
					menuShow: true,
					permission: 'dkt:examBomManage',
				},
			},
			{
				path: '/teachingAssistant/dkt',
				name: 'teachingAssistantDkt',
				component: () => import('@/views/teachingAssistant/dkt/Index.vue'),
				meta: {
					icon: '&#xe640;',
					title: '大考通教辅配置',
					menuShow: true,
					permission: 'teachingAssistant:DKT',
				},
			},
			{
				path: '/DKTOperationManage/cardPassword',
				name: 'DKTOperationManageCardPassword',
				component: () => import('../views/DKTOperationManage/cardPassword/Index.vue'),
				meta: {
					icon: '&#xe640;',
					title: '卡密生产管理',
					menuShow: true,
					permission: 'DKTOperationManageCardPassword',
				},
			},
			{
				path: '/DKTOperationManage/AITeachingSuit',
				name: 'DKTOperationManageAITeachingSuit',
				component: () => import('../views/DKTOperationManage/AITeachingSuit/Index.vue'),
				meta: {
					icon: '&#xe640;',
					title: 'AI教辅套装管理',
					menuShow: true,
					permission: 'DKTOperationManageAITeachingSuit',
				},
			},
			{
				path: '/operation/goodsManage',
				name: 'goodsManage',
				component: () => import('@/views/operation/goodsManage/GoodsManageList.vue'),
				meta: {
					icon: '&#xe77f;',
					title: '商品管理',
					menuShow: true,
					permission: 'operation:goods_manage',
				},
			},
			{
				path: '/operation/freebieManage',
				name: 'freebieManage',
				component: () => import('@/views/operation/freebieManage/FreebieManageList.vue'),
				meta: {
					icon: '&#xe77f;',
					title: '赠品管理',
					menuShow: true,
					permission: 'operation:freebie_manage',
				},
			},
			{
				path: '/operation/examOrderManage',
				name: 'orderExamManage',
				component: () => import('@/views/operation/examOrderManage/ExamOrderManageList.vue'),
				meta: {
					icon: '&#xe77f;',
					title: '试卷下载批改订单管理',
					menuShow: true,
					permission: 'operation:exam_order_manage',
				},
			},
			{
				path: '/operation/groupOrderManage',
				name: 'orderGroupManage',
				component: () => import('@/views/operation/groupOrderManage/OrderManageList.vue'),
				meta: {
					icon: '&#xe77f;',
					title: '团购订单管理',
					menuShow: true,
					permission: 'operation:group_order_manage',
				},
			},
			{
				path: '/operation/reviewCodeCreateMange',
				name: 'reviewCodeCreateMange',
				component: () => import('@/views/operation/reviewCode/ReviewCodeCreateManage.vue'),
				meta: {
					icon: '&#xe640;',
					title: '批阅码生产单管理',
					menuShow: true,
					permission: 'operation:review_code_batch',
				},
			},
			{
				path: '/operation/reviewCodeMange',
				name: 'reviewCodeMange',
				component: () => import('@/views/operation/reviewCode/ReviewCodeManage.vue'),
				meta: {
					icon: '&#xe640;',
					title: '批阅码管理',
					menuShow: true,
					permission: 'operation:review_code_manage',
				},
			},
			{
				path: '/operation/reviewCodeUseMange',
				name: 'reviewCodeUseMange',
				component: () => import('@/views/operation/reviewCode/ReviewCodeUseManage.vue'),
				meta: {
					icon: '&#xe640;',
					title: '批阅码使用管理',
					menuShow: true,
					permission: 'operation:review_code_use',
				},
			},
			{
				path: '/operation/weekLecture',
				name: 'weekLecture',
				component: () => import('@/views/operation/weekLecture/index.vue'),
				meta: {
					icon: '&#xe640;',
					title: '大考通每周开讲管理',
					permission: 'operation:week_lecture_manage',
				},
			},
			{
				path: '/operation/topicContentManage',
				name: 'topicContentManage',
				component: () => import('@/views/operation/topicContentManage/topicContentManage.vue'),
				meta: {
					icon: '&#xe640;',
					title: '专题内容管理',
					menuShow: true,
					permission: 'operation:topic_content_manage',
				},
			},
			{
				path: '/operation/topicContentDetail',
				name: 'topicContentDetail',
				component: () => import('@/views/operation/topicContentDetail/index.vue'),
				meta: {
					icon: '&#xe640;',
					title: '专题详情',
					permission: 'operation:topic_content_manage',
				},
			},
			{
				path: '/operation/eventManage',
				name: 'eventManage',
				component: () => import('@/views/operation/eventManage/EventManageList.vue'),
				meta: {
					icon: '&#xe77f;',
					title: '活动管理',
					menuShow: true,
					permission: 'operation:event_manage',
				},
			},
		],
	},
	{
		path: '/teachingAssistant',
		name: 'teachingAssistant',
		component: Main,
		meta: {
			icon: '&#xe640;',
			title: '教辅配置',
			menuShow: false,
			permission: 'teachingAssistant',
		},
		children: [
			{
				path: '/teachingAssistant/dkt',
				name: 'teachingAssistantDkt',
				component: () => import('@/views/teachingAssistant/dkt/Index.vue'),
				meta: {
					icon: '&#xe640;',
					title: '大考通教辅配置',
					menuShow: true,
					permission: 'teachingAssistant:DKT',
				},
			},
			{
				path: '/teachingAssistant/dktConfiguration',
				name: 'teachingAssistantDktConfiguration',
				component: () => import('@/views/teachingAssistant/dkt/dktConfiguration.vue'),
				meta: {
					icon: '&#xe640;',
					title: '大考通教辅配置',
					menuShow: false,
					permission: 'teachingAssistant:DKT',
				},
			},
		],
	},
	{
		path: '/operation',
		name: 'operation',
		redirect: '/operation/wrongBook',
		component: Main,
		meta: {
			title: '运营管理',
			icon: '&#xe655;',
			permission: 'operation',
			menuShow: true,
		},
		children: [
			{
				path: '/operation/wrongBook',
				name: 'wrongBook',
				component: () => import('@/views/operation/wrongBook/WrongBook.vue'),
				meta: {
					icon: '&#xe77f;',
					title: '错题本生成',
					menuShow: true,
					permission: 'operation:error_book_generate',
				},
			},

			{
				path: '/operation/bookVersionManage',
				name: 'bookVersionManage',
				component: () => import('@/views/operation/bookVersionManage/BookVersionManage.vue'),
				meta: {
					icon: '&#xe77f;',
					title: '教材版本管理',
					menuShow: false,
					permission: 'operation:book_version_manage',
				},
			},

			{
				path: '/operation/layerContentManage',
				name: 'layerContentManage',
				component: () => import('@/views/operation/layerContentManage/LayerContentManage.vue'),
				meta: {
					icon: '&#xe632;',
					title: '分层内容管理',
					menuShow: true,
					permission: 'operation:layer_content_manage',
				},
			},

			{
				path: '/operation/subLayerContentManage',
				name: 'subLayerContentManage',
				component: () => import('@/views/operation/layerContentManage/SubLayerContentManage.vue'),
				meta: {
					icon: '&#xe77f;',
					title: '分层内容管理子页面',
					menuShow: false,
					permission: 'operation:sub_layer_content_manage',
				},
			},
			{
				path: '/operation/layerContentManage/pdf',
				name: 'subLayerContentManagePDF',
				component: () => import('@/views/operation/layerContentManage/SubLayerContentPdf.vue'),
				meta: {
					icon: '&#xe77f;',
					title: 'PDF详情',
					menuShow: false,
					permission: 'operation:sub_layer_content_manage_pdf',
				},
			},

			{
				path: '/operation/downloadWrongBook',
				name: 'downloadWrongBook',
				component: () => import('@/views/operation/downloadWrongBook/DownloadWrongBook.vue'),
				meta: {
					icon: '&#xe667;',
					title: '错题本下载',
					menuShow: true,
					permission: 'operation:error_book_download',
					source: 'my',
				},
			},
			{
				path: '/operation/generateBookPdf',
				name: 'generateBookPdf',
				component: () => import('@/views/operation/generateBookPdf/index.vue'),
				meta: {
					icon: '&#xe632;',
					title: '练习册生成',
					menuShow: true,
					permission: 'operation:book_pdf_generate',
				},
			},
			{
				path: '/operation/downloadBookPdf',
				name: 'downloadBookPdf',
				component: () => import('@/views/operation/downloadBookPdf/index.vue'),
				meta: {
					icon: '&#xe855;',
					title: '练习册下载',
					menuShow: true,
					permission: 'operation:book_pdf_download',
				},
			},
			{
				path: '/operation/homeworkList',
				name: 'homeworkList',
				component: () => import('@/views/operation/HomeworkList.vue'),
				meta: {
					icon: '&#xe9ad;',
					title: '作业布置查询',
					menuShow: true,
					permission: 'operation:homework_list',
				},
			},
			{
				path: '/operation/homeworkGenerate',
				name: 'homeworkGenerate',
				component: () => import('@/views/operation/homeworkGenerate/HomeworkGenerate.vue'),
				meta: {
					icon: '&#xe600;',
					title: '个性化作业生成',
					menuShow: true,
					permission: 'operation:generate_homework',
				},
			},
			{
				path: '/operation/exerciseBookConfig',
				name: 'exerciseBookConfig',
				component: () => import('@/views/operation/exerciseBookConfiguration/ExerciseBookConfig.vue'),
				meta: {
					icon: '&#xe77f;',
					title: '学校练习册配置',
					menuShow: true,
					permission: 'operation:exercise_book_config',
				},
			},
			{
				path: '/operation/subExerciseBookConfig',
				name: 'subExerciseBookConfig',
				component: () => import('@/views/operation/exerciseBookConfiguration/SubExerciseBookConfig.vue'),
				meta: {
					icon: '&#xe77f;',
					title: '学校练习册配置详情',
					menuShow: false,
					permission: 'operation:exercise_book_config',
				},
			},
			{
				path: '/operation/correctOrderManage',
				name: 'correctOrderManage',
				component: () => import('@/views/operation/correctOrder/CorrectOrderManage.vue'),
				meta: {
					icon: '&#xe77f;',
					title: '错题批改订单管理',
					menuShow: true,
					permission: 'operation:correct_order_manage',
				},
			},
			{
				path: '/operation/textBookVersion',
				name: 'textBookVersionManage',
				component: () => import('@/views/operation/textBookVersion/TextBookVersionManage.vue'),
				meta: {
					icon: '&#xe6a2;',
					title: '教材版本管理',
					menuShow: true,
					permission: 'operation:text_book_version_manage',
				},
			},
		],
	},
	{
		path: '/operationData',
		name: 'operationData',
		redirect: '/operationData/wrongBookPdfDownloadRecords',
		component: Main,
		meta: {
			title: '运营数据查询统计',
			icon: '&#xe655;',
			permission: 'operationData',
			menuShow: true,
		},
		children: [
			{
				path: '/operationData/wrongBookPdfDownloadRecords',
				name: 'wrongBookPdfDownloadRecords',
				component: () => import('@/views/operation/downloadWrongBook/DownloadWrongBook.vue'),
				meta: {
					icon: '&#xe77f;',
					title: '错题本PDF生成记录查询',
					menuShow: true,
					permission: 'operationData:wrong_book_pdf_download_records',
				},
			},
			{
				path: '/operationData/channelCorrectRecords',
				name: 'channelCorrectRecords',
				component: () => import('@/views/operation/channelCorrect/ChannelCorrectRecord.vue'),
				meta: {
					icon: '&#xe77f;',
					title: '渠道批改数据查询',
					menuShow: true,
					permission: 'operationData:channel_correct_records',
				},
			},
		],
	},
	{
		path: '/dataObserve',
		name: 'dataObserve',
		redirect: '/dataObserve/taskQueueData',
		component: Main,
		meta: {
			title: '数据监控',
			icon: '&#xe655;',
			permission: 'dataObserve',
			menuShow: true,
		},
		children: [
			{
				path: '/dataObserve/taskQueueData',
				name: 'taskQueueData',
				component: () => import('@/views/operation/dataObserve/taskQueueData.vue'),
				meta: {
					icon: '&#xe678;',
					title: '任务队列数据',
					menuShow: true,
					permission: 'dataObserve:task_queue_data',
				},
			},
			{
				path: '/dataObserve/operationLog',
				name: 'operationLog',
				component: () => import('@/views/operation/dataObserve/operationLog.vue'),
				meta: {
					icon: '&#xe640;',
					title: '操作日志记录',
					menuShow: true,
					permission: 'dataObserve:operation_log',
				},
			},
		],
	},
	{
		path: '/login',
		name: 'login',
		component: () => import('@kzfe/learnable-lib/package/vue3/framework/views/Login.vue'),
		meta: {
			noLogin: true,
		},
	},
	{
		path: '/test',
		name: 'test',
		component: () => import('@/views/test/Test.vue'),
		meta: {
			noLogin: true,
		},
	},
	...frameworkRoutes,
]

export const constRoutes = recursionGetConstRoute(routes)

const router = createRouter({
	history: createWebHistory(import.meta.env.BASE_URL),
	routes: constRoutes,
})

export default router
export function settingRoutes() {
	settingRoutesG(router, routes)
}

initFramework(import.meta.env, G, store, router, DOMAIN_MAP, settingRoutes)

router.afterEach(to => {
	NProgress.done()
	setPageTitle(to.meta.title)
	if (isLogin()) {
		const module = store.state[VuexModuleKey.enumModule] as {
			[x: string]: unknown
		}
		if (module) {
			const serverEnum = module[VuexStateKey.serverAllEnum] as {
				[key: string]: EnumItem
			}
			const keys = Object.keys(serverEnum)
			if (!keys.length) {
				store.dispatch(`${VuexModuleKey.enumModule}/${VuexActionKey.queryAllEnum}`)
			}
		}
	}
})

router.beforeEach((to, from, next) => {
	NProgress.start()
	routerBeforeEach(to, from, next)
})
