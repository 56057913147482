import { App } from 'vue'
import form from './form'
import table from './table'
import kzTitle from './kzTitle'
const components = [form, table, kzTitle]

export default {
	install(app: App) {
		components.forEach(component => app.use(component))
	},
}
