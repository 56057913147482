import { LocalStorageKey } from '@/config/enum'

export class VuexStateKey {
	static G = 'G'
	static userInfo = LocalStorageKey.USER_INFO
	static auths = 'auths'
	static menuList = 'menuList'
	/**枚举相关key */
	static allSchoolTypeEnum = 'allSchoolTypeEnum'
	static serverAllEnum = 'serverAllEnum'
	static subjectList = 'subjectList'
	static subjectGroupList = 'subjectGroupList'
	/**枚举相关key */
	static teacherReviewValidity = 'teacherReviewValidity'
	static validityConsistency = 'validityConsistency'
	static feedbackIssue = 'feedbackIssue'
	static aiValidity = 'aiValidity'
	static checkStatus = 'checkStatus'
	static designWorkSourceEnum = 'designWorkSourceEnum'
	static taskTypeEnum = 'taskTypeEnum'
	static channelUserType = 'channelUserType'
	/**渠道相关key */
	static channelPartnerTypeEnum = 'channelPartnerTypeEnum'
	static channelProvinceList = 'channelProvinceList'
}
export class VuexMutationKey {
	static updateUserInfo = 'updateUserInfo'
	static updateAuths = 'updateAuths'
	static updateMenuList = 'updateMenuList'
	static updateServerAllEnum = 'updateServerAllEnum'
	static updateSubjectListEnum = 'updateSubjectListEnum'
	static updateSubjectGroupListEnum = 'updateSubjectGroupListEnum'
	/**枚举相关key */
}
export class VuexActionKey {
	static queryAllEnum = 'queryAllEnum'
	static querySubjectEnum = 'querySubjectEnum'
	static queryChannelProvince = 'queryChannelProvince'
}
export class VuexModuleKey {
	static enumModule = 'enumModule'
}
