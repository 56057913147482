<template>
	<el-table
		ref="tableRef"
		class="table"
		:data="data"
		v-loading="tableLoading"
		:element-loading-text="elementLoadingText"
		:max-height="maxHeight"
		v-bind="$attrs"
		@select-all="selectAll"
		@select="select"
		@selection-change="selectionChange"
	>
		<template v-for="(item, index) in tableOptions" :key="index">
			<el-table-column
				v-if="!item.slot"
				:label="item.label"
				:prop="item.prop"
				:width="item.width"
				:align="item.align"
				:type="item.type"
				:index="item.index"
			></el-table-column>
			<el-table-column v-else :label="item.label" :prop="item.prop" :width="item.width" :align="item.align">
				<template #default="scope">
					<slot :name="item.slot" :scope="scope"></slot>
				</template>
			</el-table-column>
		</template>
		<el-table-column :label="actionOption?.label" :align="actionOption?.align" :width="actionOption?.width">
			<template #default="scope">
				<slot name="action" :scope="scope"></slot>
			</template>
		</el-table-column>
	</el-table>
	<div v-if="pagination" class="pagination" :style="{ justifyContent }">
		<el-pagination
			:current-page="currentPage"
			:page-sizes="pageSizes"
			:page-size="pageSize"
			:layout="layout"
			:total="total"
			@size-change="handleSizeChange"
			@current-change="handleCurrentChange"
		></el-pagination>
	</div>
</template>

<script setup lang="ts">
import { ElTable } from 'element-plus'
import { PropType, computed, defineProps, defineEmits, defineExpose } from 'vue'
import { TableOptions } from './types'

const props = defineProps({
	options: {
		type: Array as PropType<TableOptions[]>,
		required: true,
	},
	data: {
		type: Array,
		required: true,
	},
	layout: {
		type: String,
		default: 'total, sizes, prev, pager, next, jumper',
	},
	// 加载文案
	elementLoadingText: {
		type: String,
	},
	// 后续需要的时候打开
	// 加载背景色
	// elementLoadingBackground: {
	// 	type: String,
	// },
	// 加载图标名
	// elementLoadingSpinner: {
	// 	type: String,
	// },
	// 加载图标是svg
	// elementLoadingSvg: {
	// 	type: String,
	// },
	// 加载团是svg的配置
	// elementLoadingSvgViewBox: {
	// 	type: String,
	// },
	// 是否加载
	tableLoading: {
		type: Boolean,
		default: false,
	},
	// 是否显示分页
	pagination: {
		type: Boolean,
		default: false,
	},
	// 显示分页的对齐方式
	paginationAlign: {
		type: String as PropType<'left' | 'center' | 'right'>,
		default: 'right',
	},
	// 当前是第几页
	currentPage: {
		type: Number,
		default: 1,
	},
	// 当前一页多少条数据
	pageSize: {
		type: Number,
		default: 10,
	},
	// 显示分页数据多少条的选项
	pageSizes: {
		type: Array,
		default: () => [10, 20, 30, 50],
	},
	// 数据总条数
	total: {
		type: Number,
		default: 0,
	},
	maxHeight: {
		type: Number || String,
		default: 'auto',
	},
})
const emits = defineEmits(['size-change', 'current-change', 'select', 'select-all', 'selection-change'])
const tableRef = $ref<InstanceType<typeof ElTable>>()

function getTableRef() {
	return tableRef
}

defineExpose({
	getTableRef,
})

// 过滤出来非操作项的配置
const tableOptions = computed(() => props.options.filter(item => !item.action))

// 找到操作项配置
const actionOption = computed(() => props.options.find(item => item.action))

// 是否展示loading
// const isLoading = computed(() => !props.data || !props.data.length)

// 分页插件排列方式
const justifyContent = computed(() => {
	if (props.paginationAlign === 'left') return 'flex-start'
	else if (props.paginationAlign === 'right') return 'flex-end'
	else return 'center'
})

// 分页的每一页数据变化
const handleSizeChange = (val: number) => {
	emits('size-change', val)
}

// 分页页数改变
const handleCurrentChange = (val: number) => {
	emits('current-change', val)
}

// 选择某一项时触发
const select = (selection: any, row: any) => {
	emits('select', selection, row)
}
// 全选时触发
const selectAll = (selection: any) => {
	emits('select-all', selection)
}
const selectionChange = (selection: any) => {
	emits('selection-change', selection)
}
</script>

<style lang="scss" scoped>
.table {
	width: 95%;
	margin: 0 auto;
}
.pagination {
	margin-top: 16px;
	margin-right: 32px;
	display: flex;
}
</style>
