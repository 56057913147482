import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import App from '@kzfe/learnable-lib/package/vue3/framework/App.vue'
import router from './router'
import store from './store'
import '@/styles/index.scss'
import 'img-mark/dist/style.css'
import '@kzfe/learnable-lib/dist/style.css'
import 'element-plus/theme-chalk/index.css'
import 'dayjs/locale/zh-cn'
import locale from 'element-plus/es/locale/lang/zh-cn'
import kUI from './components'
// import * as Sentry from '@sentry/vue'
// import { BrowserTracing } from '@sentry/tracing'
import { EnvName } from '@/config/NodeEnv'
import packageJson from '../package.json'
import { datafluxRum } from '@cloudcare/browser-rum'
import { DOMAIN_MAP } from '@/config/config'

const app = createApp(App)
// datafluxRum.init({
// 	applicationId: 'EEOPV3',
// 	datakitOrigin: process.env.NODE_ENV === EnvName.PRODUCTION ? 'http://datakit-online.kezhitech.com' : 'http://datakit-test.kezhitech.com', // 协议（包括：//），域名（或IP地址）[和端口号]
// 	env: import.meta.env.MODE,
// 	version: packageJson.version,
// 	sessionSampleRate: 100,
// 	sessionReplaySampleRate: 70,
// 	trackInteractions: true,
// 	traceType: 'ddtrace',
// 	allowedTracingOrigins: Object.values(DOMAIN_MAP),
// })
// datafluxRum.startSessionReplayRecording() // 会话重放
app.config.globalProperties.$t = undefined

app.use(ElementPlus, {
	locale,
})
app.use(kUI) // 全局注册封装的组件
app.use(store).use(router).mount('#app')
// if (process.env.NODE_ENV === EnvName.PRODUCTION) {
// 	Sentry.init({
// 		app,
// 		dsn: 'https://49c16d3d1f4147f3a325def717611108@sentry.kezhitech.com/35',
// 		release: packageJson.version,
// 		// debug: true,
// 		tracesSampleRate: 1.0,
// 		integrations: [new BrowserTracing()],
// 		ignoreErrors: [
// 			// Random plugins/extensions
// 			'top.GLOBALS',
// 			// See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
// 			'originalCreateNotification',
// 			'canvas.contentDocument',
// 			'MyApp_RemoveAllHighlights',
// 			'http://tt.epicplay.com',
// 			"Can't find variable: ZiteReader",
// 			'jigsaw is not defined',
// 			'ComboSearch is not defined',
// 			'http://loading.retry.widdit.com/',
// 			'atomicFindClose',
// 			// Facebook borked
// 			'fb_xd_fragment',
// 			// ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
// 			// reduce this. (thanks @acdha)
// 			// See http://stackoverflow.com/questions/4113268
// 			'bmi_SafeAddOnload',
// 			'EBCallBackMessageReceived',
// 			// See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
// 			'conduitPage',
// 		],
// 		denyUrls: [
// 			// Facebook flakiness
// 			/graph\.facebook\.com/i,
// 			// Facebook blocked
// 			/connect\.facebook\.net\/en_US\/all\.js/i,
// 			// Woopra flakiness
// 			/eatdifferent\.com\.woopra-ns\.com/i,
// 			/static\.woopra\.com\/js\/woopra\.js/i,
// 			// Chrome extensions
// 			/extensions\//i,
// 			/^chrome:\/\//i,
// 			// Other plugins
// 			/127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
// 			/webappstoolbarba\.texthelp\.com\//i,
// 			/metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
// 		],
// 		beforeSend: (event, hint) => {
// 			if (!hint) return null
// 			const { response } = hint.originalException as Record<string, any>
// 			if (response && response.status === 404) {
// 				return null
// 			}
// 			return event
// 		},
// 	})
// }
/** 用于解决将空字符串和null也判定为数字的问题 */
const _isNaN = isNaN
window.isNaN = function (val: any) {
	if (val === '' || val === null) {
		return true
	}
	return _isNaN(val)
}
export default app
