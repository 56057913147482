import { apiAllProvince } from '@/api/DKTOperateMange'
import { VuexMutationKey, VuexStateKey, VuexActionKey } from '../vuexEnum'
import { AllTypeEnumList, apiQueryAllEnum } from '@/api/enum'
import { Module } from 'vuex'
const enumModule: Module<
	{
		[x: string]: unknown
	},
	unknown
> = {
	namespaced: true,
	state: {
		[VuexStateKey.serverAllEnum]: {},
		[VuexStateKey.allSchoolTypeEnum]: [
			[],
			['小小班', '小班', '中班', '大班'],
			['一年级', '二年级', '三年级', '四年级', '五年级', '六年级'],
			['七年级', '八年级', '九年级'],
			['十年级', '十一年级', '十二年级'],
			['七年级', '八年级', '九年级', '十年级', '十一年级', '十二年级'],
			['一年级', '二年级', '三年级', '四年级', '五年级', '六年级', '七年级', '八年级', '九年级'],
			['一年级', '二年级', '三年级', '四年级', '五年级', '六年级', '七年级', '八年级', '九年级', '十年级', '十一年级', '十二年级'],
		],
		[VuexStateKey.designWorkSourceEnum]: {
			'-1': '练习册',
			0: '批改题库',
			1: '教研题库',
			2: '班级错题',
		},
		[VuexStateKey.taskTypeEnum]: {
			1: '练习册作业',
			3: '设计作业',
		},
		[VuexStateKey.channelUserType]: {
			2: '教师',
			3: '家长',
			6: '三方用户',
		},
		[VuexStateKey.channelPartnerTypeEnum]: [
			{
				label: '全部',
				value: null
			},
			{
				label: '线上渠道',
				value: 1
			},
			{
				label: '线下渠道',
				value: 2
			}
		],
		[VuexStateKey.channelProvinceList]: [],
	},
	mutations: {
		[VuexMutationKey.updateServerAllEnum](state, allEnum: AllTypeEnumList) {
			state[VuexStateKey.serverAllEnum] = allEnum
		},
	},
	actions: {
		async [VuexActionKey.queryAllEnum]({ commit }) {
			const allEnum = await apiQueryAllEnum()
			commit(VuexMutationKey.updateServerAllEnum, allEnum)
		},
		async [VuexActionKey.queryChannelProvince]({ state }) {
			const allProvince = await apiAllProvince()
			state[VuexStateKey.channelProvinceList] = allProvince
		},
	},
}

export default enumModule
