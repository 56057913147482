import http from '@kzfe/learnable-lib/package/vue3/framework/utils/http'
import { PageListRecords } from 'types/PageList'
import { SchoolItem } from '@/api/system/school'

export interface ClassNameItem {
	//班级唯一标识
	id: number
	className: string
	fullClassName: string
}
/** 查询更换弹窗内教辅练习册列表 数据 */
export interface ExerciseBookItem {
	exerciseBookIsbn: string
	exerciseBookName: string
	exerciseBookSubject: string | number
	exerciseBookGrade: number | string
	updateTime: string
	templateType: string
}
export interface ExerciseBookData {
	records: ExerciseBookItem
}
/** 生成卡密参数 */
export interface CreateCardParams {
	type: string
	collectionId: string
	codeCount: number
}
/** 生成卡密 */
export function apiCreateCard(params: CreateCardParams) {
	return http<number[] | null>('POST', '/api/admin/finalExam/generateActivationCode', params)
}
/** 查询更换弹窗内教辅练习册列表 入参 */
export interface ExerciseBookParams {
	exerciseBookSubject: number | string
	exerciseBookIsbn?: string
	exerciseBookName?: string
	teachingVersionId?: string | number
	pageSize: number
	pageNo: number
	teachingGrade?: number | string
}
interface ExerciseBookRelation {
	exerciseBookId: number | string
	exerciseBookName: string
	orderIndex: number
}
/** 查询卡密列表条件 */
interface CardPasswordList {
	batchNumber?: string
	operator?: string
	productionStatus?: string
	status?: string
	pageNo: string | number
	pageSize: string | number
}
/** 新增-查询选择版本 */
export function querVersionList(params: { subject: number }) {
	return http<number[] | null>('GET', '/api/admin/exerciseBookCollection/queryTeachingVersionBySubject', params)
}

/** 新增版本-确定 */
export function addNewExercise(params: { teachingVersionId: number; collectionName: string; type: 6 | 7 }) {
	return http<number[] | null>('POST', '/api/admin/exerciseBookCollection/add', params)
}
/** 更换，新增弹窗列表 */
export function queryExerciseBookList(params: ExerciseBookParams) {
	return http<ClassNameItem[] | null>('POST', '/api/admin/exerciseBookCollection/queryExerciseBookList', params)
}
/** 分层练习册删除 */
export function deleteCollection(params: { exerciseBookCollectionId: number }) {
	return http<ClassNameItem[] | null>('GET', '/api/admin/exerciseBookCollection/delete', params)
}
/** 包含添加练习册、删除练习册、练习册排序、更换练习册操作 */
export function updateBookList(params: { exerciseBookCollectionId: number; exerciseBookRelationList: ExerciseBookRelation[] }) {
	return http<ClassNameItem[] | null>('POST', '/api/admin/exerciseBookCollection/maintain', params)
}
/** 创建套装 */
export interface ExerciseBookListSuit {
	isbn?: string
	exerciseBookName?: string
	exerciseBookIsbn?: string
	orderIndex: number
}
export interface CreateSuit {
	id?: number
	name: string
	exerciseBookList: ExerciseBookListSuit[]
}
/** 创建套装 */
export function apiCreateSuit(params: CreateSuit) {
	return http<null>('POST', '/api/admin/finalExam/addTeachingAssistant', params)
}
/** 查询大考通教辅列表 0 AI套装列表 1 卡密筛选下拉列表 */
export function queryDKTTeachingSuitList(params: { type: 0 | 1 }) {
	return http<number[] | null>('GET', '/api/admin/finalExam/teachingAssistantList', params)
}
export interface cardPasswordInfo {
	batchNumber: number
	codeCount: string
	type: string
	collectionName: string
	createTime: string
	productionType: string
	status: string
	issueUser: string
}
/** 查询卡密生成记录列表 */
export function queryCardPasswordList(params: CardPasswordList) {
	return http<{ total: number; records: cardPasswordInfo }>('POST', '/api/admin/finalExam/activationCode/collectionList', params)
}
/** 查询卡密类型 */
export function apiQuertCardType() {
	return http<number[] | null>('GET', '/api/admin/finalExam/activationCode/typeList', {})
}

/** 配置教辅列表 */
export function queryExerciseDetailList(params: { exerciseBookCollectionId: number | string | null }) {
	return http<number[] | null>('GET', '/api/admin/exerciseBookCollection/query', params)
}
/** 查询教材年级 */
export function queryGrade(params: { subjectId: any; teachingVersion: any }) {
	return http<number[] | null>('GET', '/subjectTextbookChapter/queryTeachingGradeBySubjectAndVersion', params, {
		domainName: 'question',
	})
}
export interface QueryRecordsParams {
	batchNumber: number
	productionStatus: string
	notes: string
	issueUser?: string
	createTime?: string
}
/** 查询记录 */
export function apiQueryRecords(params: { batchNumber: number }) {
	return http<QueryRecordsParams[] | null>('GET', '/api/admin/finalExam/activationCode/productionStatusList', params)
}
/** 添加记录 */
export function apiAddRecords(params: QueryRecordsParams) {
	return http<number[] | null>('POST', '/api/admin/finalExam/activationCode/recordProductionStatus', params)
}
/** 卡密生产状态 */
export const productionStatusDict = {
	0: ['生成中', ''],
	1: ['未导出', 'primary'],
	2: ['已导出', 'warning'],
	3: ['已印刷', 'success'],
	4: ['已入仓', 'danger'],
}
/** 卡密生产状态 */
export const statusDict = {
	0: ['已启用', 'success'],
	1: ['已禁用', 'danger'],
}
/** 卡密生产状态 */
export const recordProductionStatusDict = {
	2: ['首次导出', 'primary'],
	3: ['印刷完成', 'success'],
	4: ['入仓完成', 'danger'],
}

export const recordProductionStatusDict2 = {
	3: ['印刷完成', 'success'],
	4: ['入仓完成', 'danger'],
}
/** 卡密生类型 */
export const cardTypeDict = {
	1: '实体教辅书',
}

/** 渠道信息 */
export interface ChannelPartnerItem {
	channelPartnerId?: number
	channelMarkId?: string
	channelPartnerName?: string
	channelPartnerType?: number // 渠道类型 ONLINE(1, "线上渠道"),     OFFLINE(2, "线下渠道"),
	provinceCode?: number
	area?: string
	comment?: string
	status?: number // 渠道状态 ENABLE(1, "启用"),     DISABLE(2, "禁用"),
	createUser?: number
	createUserName?: string
	createTime?: number
	schoolInfoList?: Partial<schoolItem>[]
}
export interface QueryChannelPartnerParam {
	pageNo: number
	pageSize: number
	channelMarkId?: string | null
	channelPartnerName?: string | null
	channelPartnerType?: number | null
	provinceCode?: number | null
	channelStatus?: number | null
}

export interface QueryStatusParam {
	status?: number
	batchNumber: number
}

/** 渠道状态 */
export const channelStatusDict: { [prop: string]: any } = {
	1: ['启用', 'success'],
	2: ['禁用'],
}

/** 禁用 */
export function QueryUpdateStatus(params: QueryStatusParam) {
	return http<PageListRecords<ChannelPartnerItem>>('POST', '/api/admin/finalExam/activationCode/updateStatus', params)
}

/** 导出 */
export function QueryExportBatch(params: QueryStatusParam) {
	return http<PageListRecords<ChannelPartnerItem>>('POST', '/api/admin/finalExam/activationCode/exportBatch', params, {
		responseType: 'blob',
		sourceData: true,
	})
}

/** 分页查询渠道信息 */
export function apiQueryChannelPartner(params: QueryChannelPartnerParam) {
	return http<PageListRecords<ChannelPartnerItem>>('POST', '/api/admin/channelPartner/pageQuery', params)
}

/** 创建渠道 */
export interface CreateChannelPartnerParam {
	channelPartnerName?: string
	channelPartnerType?: number | null
	provinceCode?: number | null
	comment?: string
	schoolIdList?: Partial<SchoolItem>[] // 学校ID列表
}

export function apiCreateChannelPartner(params: CreateChannelPartnerParam) {
	return http<number>('POST', '/api/admin/channelPartner/create', params)
}

/** 更新渠道 */
export interface UpdateChannelPartnerParam extends CreateChannelPartnerParam {
	channelPartnerId?: number
	status?: number
}

export function apiUpdateChannelPartner(params: UpdateChannelPartnerParam) {
	return http<boolean>('POST', '/api/admin/channelPartner/update', params)
}

// 查询渠道下可用的学校列表
export interface schoolItem {
	channelPartnerId: number
	channelPartnerSchoolMarkId: string
	id: number
	schoolId: number
	schoolName: string
}
export function apiQuerySchoolList(params: { channelPartnerId?: number }) {
	return http<schoolItem[]>('GET', '/api/admin/channelPartner/schoolList', params)
}

// 查询试卷信息
export interface ExaminationPaper {
	province: string
	examinationPaperList: { exerciseBookId: string; exerciseBookName: string }
}

export function apiQueryExaminationPaper() {
	return http<ExaminationPaper>('POST', '/api/admin/exerciseBook/queryExaminationPaper', {})
}

// 物料结构
export interface MaterialAttributesItem {
	materialType: number // 枚举: 1
	materialId: number
	materialName: string
	quantity?: number
}
//创建BOM
export interface CreateBomParam {
	salesType: number | null // 枚举: 1,2
	scope: string
	materialAttributesList: MaterialAttributesItem[]
	channelPartnerId: number | null
}

export function apiCreateBom(params: UpdateBomParam) {
	return http<{ bomId: number }>('POST', '/api/admin/bom/create', params)
}

// 查询BOM
export interface QueryBomParam {
	pageSize: number
	pageNo: number
	batchNumber: string | null
	status: number | null // 枚举: 1,2,3,4
	createUserName: string | null
	salesType: number | null // 售卖方式
	channelPartnerId: number | null // 关联渠道id
}

export interface BomItem {
	bomId: number
	batchNumber: string
	salesType: number
	status: number
	scope: string
	bomMaterialQuantity: number
	channelPartnerId: number
	channelPartnerName: string
	createUser: number
	createUserName: string
	createTime: number
	materialAttributesList: MaterialAttributesItem[]
	finalProduct: string
	finalProductUrl: string
	schoolId?: number
}

export function apiQueryBom(params: QueryBomParam) {
	return http<PageListRecords<BomItem>>('POST', '/api/admin/bom/pageQuery', params)
}

// 更新BOM，包括状态调整
export interface UpdateBomParam {
	bomId?: number | null
	salesType: number | null
	scope: string
	materialAttributesList: MaterialAttributesItem[]
	channelPartnerId: number | null
	status?: number | null
	schoolId?: number
}

export function apiUpdateBom(params: UpdateBomParam) {
	return http<boolean>('POST', '/api/admin/bom/update', params)
}

// 下载物料清单
export function apiDownloadBom(params: { bomId: number }) {
	return http<{ downloadUrl: string }>('POST', '/api/admin/bom/download', params)
}

// 查询所有省份信息
export interface ProvinceItem {
	name: string
	code: number | null
	province: string
}

export function apiAllProvince() {
	return http<ProvinceItem[]>('GET', '/api/admin/province/all', {})
}

// 查询渠道用户列表
export interface QueryChannelUserParam {
	userId: number | null
	phone: string | null
	pageSize: number
	pageNo: number
	channelPartnerId: number
}
export interface ChannelPartnerUser {
	userId: number
	phone: string
	grade: string
	periodDesc: string
	createTime: number
	provinceName: string
	cityName: string
	sourceDesc: string
	channelBindTime: number
}
export function apiQueryByChannelPartner(params: { channelPartnerId: number | null; pageNo: number; pageSize: number }) {
	return http<PageListRecords<ChannelPartnerUser>>('POST', '/api/admin/channelPartner/pageQueryUserInfo', params)
}

// 查询渠道订单列表
export interface ChannelPartnerOrderParam {
	pageSize: number
	pageNo: number
	status: number | null
	phone: string | null
	orderNo: string | null
	orderType: number | null
	bizType: number // 业务类型 1
	channel: number | null
}

export interface ChannelPartnerOrder {
	orderNo: string // 订单号
	createTime: string // 下单时间
	status: number // 订单状态code
	amount: string // 支付金额（分）
	phone: string // 家长手机号
	schoolName: string // 所属学校
	deviceType: string // 支付端
	goodsName: string // 购买商品名
	orderName: string // 购买的订单名称
	account: string // 学生账号
	refundFlowNo?: string // 订单退款申请流水号
	refundRequestTime?: number // 用户申请退款时间戳（毫秒）
	refundCompleteTime?: number // 退款完成时间戳（毫秒）
	receiverName: string //  收件人姓名
	receiverPhone: string // 收件人电话
	receiverAddress: string // 收件人地址
	orderTypeName: string // 业务类型描述 > 订单类型
	orderType: number
	orderSource: number // 1：公众号 2：小程序 3：微信app 4：苹果 5：苹果沙箱
	expireTime?: number // 订单有效期（毫秒）
}

export function apiQueryChannelOrderList(params: ChannelPartnerOrderParam) {
	return http<PageListRecords<ChannelPartnerOrder>>('POST', '/api/order/channel/list', params)
}

// 查询省份试卷信息
export interface ProvinceBookInfo {
	province: string
	briefExerciseBookInfoResponseList: ExerciseBookInfo[]
	collapse?: boolean
}

export interface ExerciseBookInfo {
	exerciseBookName: string
	exerciseBookId: number
	select?: boolean
}

export function apiQueryProvinceExerciseBookInfo() {
	return http<ProvinceBookInfo[]>('GET', '/admin/exerciseBook/queryProvinceExerciseBookInfo', {})
}

// 查询教辅套装章节信息
interface RootChapter {
	rootChapterName: string
	leafChapterList: { leafChapterName: string; chapterId: number }[]
}
export interface ChapterItem {
	exerciseBookName: string
	exerciseBookId: string
	rootChapterList: RootChapter[]
}

export function apiTeachingAssistantChapterList(isbnList: string[]) {
	return http<ChapterItem[]>('POST', '/api/admin/finalExam/teachingAssistant/chapterList', { isbnList })
}

// 获取二维码预览图片
export function apiTeachingAssistantChapterQrcode(params: { exerciseBookId: string; chapterId: string; size: number }) {
	return http<string>('GET', '/api/admin/finalExam/teachingAssistantChapter/qrcode', params)
}

// 获取二维码压缩包
export function apiTeachingAssistantChapterQrcodeZip(params: { exerciseBookIds: string[]; size: number; teachingAssistantName: string }) {
	return http<string>('POST', '/api/admin/finalExam/teachingAssistantChapter/qrcodeZip', params)
}
